/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Error from 'next/error';
import { ThemeProvider, ErrorScreen } from '@fmi/design-system';
import App, { AppContext } from 'next/app';
import 'regenerator-runtime/runtime';
import Head from 'next/head';
import { createGlobalStyle } from 'styled-components';

import { loadApplication } from '@src/hooks';
import { fetchAuraPhaseUsedOnInterview } from '@src/utils';
import {
  ContextProvider,
  initialFormDataContextValues,
  initialProgressContextValues,
  initialQuoteContextValues,
  initialUserContextValues,
  initialAuraContextValues,
} from '@src/contexts';
import { Application, AuraPhase, User } from '@src/models';
import getConfig from 'next/config';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/nextjs';

const { publicRuntimeConfig } = getConfig();

function setupLogging(adviserName: string, applicationID: string) {
  if ((process as any).browser) {
    if (publicRuntimeConfig.logRocket.enabled) {
      LogRocket.init(publicRuntimeConfig.logRocket.apiKey);
      LogRocket.identify(adviserName);
    }
    if (
      publicRuntimeConfig.sentry.enabled &&
      process.env.NEXT_PUBLIC_SENTRY_DSN
    ) {
      Sentry.setUser({ username: adviserName });
      Sentry.configureScope(scope => {
        scope.setExtra('applicationID', applicationID);
      });
    }
    if (
      publicRuntimeConfig.logRocket.enabled &&
      publicRuntimeConfig.sentry.enabled
    ) {
      LogRocket.getSessionURL(sessionURL => {
        Sentry.configureScope(scope => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }
}
class MyApp extends App {
  static async getInitialProps(appContext: AppContext) {
    const id = appContext.router.query.id as string;
    const devMode = (appContext.router.query.dev as string) ?? '0';

    const pageProps: any = appContext.Component.getInitialProps
      ? await appContext.Component.getInitialProps(appContext.ctx)
      : {};

    const user: User =
      (appContext.ctx.req as any)?.session?.passport?.user ??
      initialUserContextValues.user;
    user.isAdviserOnQuote =
      (appContext.ctx.req as any)?.session?.authedUser?.isAdviserOnQuote ??
      false;
    user.isLifeInsured =
      (appContext.ctx.req as any)?.session?.authedUser?.isLifeInsured ?? false;

    let statusCode = 200;

    const getAndSetApplication = async () => {
      if (id) {
        try {
          const app = await loadApplication(id);
          return app;
        } catch (e) {
          statusCode = 500;
          console.error(e);
        }
      }

      return {
        formData: initialFormDataContextValues.formData,
        progress: initialProgressContextValues.progress,
        quote: initialQuoteContextValues.quote,
        shouldSendRAREmail: false,
        isFmiReplacement: false,
        isOtherReplacement: false,
        applicationId: id,
      };
    };

    const application: Application = await getAndSetApplication();

    const { aura } = initialAuraContextValues;
    const phaseUsedWithApplication: AuraPhase = await fetchAuraPhaseUsedOnInterview(
      id
    );
    if (phaseUsedWithApplication) {
      aura.phaseUsedWithApplication = phaseUsedWithApplication.auraPhase;
    }

    return {
      application,
      user,
      aura,
      pageProps,
      statusCode,
      devMode,
    };
  }

  componentDidMount() {
    const { application, router, user } = this.props as any;
    const { ifa } = application.quote;
    let applicationID = '';
    let adviserName = '';
    if (ifa && router.query.id) {
      adviserName = ifa.name;
      applicationID = router.query.id;
    }
    setupLogging(user.email || adviserName, applicationID);
  }

  render() {
    if ((this.props as any).statusCode !== 200) {
      // This line will show the default Error Page
      return <Error statusCode={(this.props as any).statusCode} />;
    }

    const { Component, pageProps, application, user, aura } = this.props as any;
    const { progress, quote, formData } = application as Application;

    const isDevMode = (this.props as any).devMode === '0';

    const isMaintenanceMode = publicRuntimeConfig.system.maintenanceMode && isDevMode;
    const urlQuoteId = (this.props as any).router.query.questionSet;
    const urlApplicationId = (this.props as any).router.query.answerSet;
    const expandedMaintenanceMode = (quote.quoteDetail.questionSetGuid !== '' || urlQuoteId !== '') && (application.applicationID !== '' || urlApplicationId !== '');
    // if (isDevMode) {
    //   console.table({
    //     isMaintenanceMode,
    //     expandedMaintenanceMode,
    //     quoteGuid: quote.quoteDetail.questionSetGuid,
    //     applicationID: application.applicationID,
    //     urlQuoteId,
    //     urlApplicationId,
    //   });
    // }

    const GlobalStyle = createGlobalStyle`
    html, body, #__next {
      height: 100%;
      font-family: 'Roboto', sans-serif;
      background: #F0F0F0;
    }
  `;

    return (
      <>
        <Head>
          {/* initialize freshchat */}
          <script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
              function initFreshChat() {
                window.fcWidget.init({
                  token: "9bce7281-45f2-4967-8bd0-ac087f6f79ac",
                  host: "https://wchat.freshchat.com",
                  tags: ["dca_support"],
                  config: {
                    "headerProperty": {
                      "hideChatButton": true
                    },
                  },
                });
                window.fcWidget.user.setProperties({
                  'lifeInsuredTitle': '${quote.lifeInsured.title}',
                  'lifeInsuredName': '${quote.lifeInsured.firstName}',
                  'lifeInsuredSurname': '${quote.lifeInsured.surname}',
                  'quoteNumber': '${quote.quoteDetail.quoteNumber}',
                })
                .then(res => console.info('Freshchat user properties set with status:', res.status))
                .catch(error => console.warn(error));
              }
              function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
              `,
            }}
          />
        </Head>
        <ThemeProvider>
          {!isMaintenanceMode && (
            <ContextProvider
              application={{
                isFmiReplacement: application.isFmiReplacement,
                isOtherReplacement: application.isOtherReplacement,
              }}
              progress={progress}
              quote={quote}
              formData={formData}
              user={user}
              aura={aura}>
              <Component {...pageProps} />
            </ContextProvider>
          )}
          {isMaintenanceMode && (
            <>
              <GlobalStyle />
              {expandedMaintenanceMode && (
                <ErrorScreen
                  headerText="Maintenance Mode"
                  heading="Something’s happened on our side..."
                  subheading={[
                    'Apologies for the inconvenience but the system is currently offline. Please use the electronic application for now while we sort out the issue.',
                  ]}
                  primaryButtonText="BACK TO QUOTES"
                  primaryButtonOnClick={() => {
                    window.open(
                      `${publicRuntimeConfig.urls.legacyAppUrl}/QA.aspx?Q=d873b961-508a-4781-a349-d6619d00a852`,
                      '_self'
                    );
                  }}
                  secondaryButtonText="Use Electronic Application"
                  secondaryButtonOnClick={() => {
                    window.open(
                      `${publicRuntimeConfig.urls.legacyAppUrl}/QA.aspx?Q=${quote.quoteDetail.questionSetGuid !== '' ? quote.quoteDetail.questionSetGuid : urlQuoteId}&external=1&PGUID=${application.applicationID !== '' && application.applicationID !== undefined ? application.applicationID : urlApplicationId}&origin=dca`,
                      '_self'
                    );
                  }}
                  illustration="error"
                />
              )}
              {!expandedMaintenanceMode && (
                <ErrorScreen
                  headerText="Maintenance Mode"
                  heading="Something’s happened on our side..."
                  subheading={[
                    'Apologies for the inconvenience but the system is currently offline.',
                  ]}
                  primaryButtonText="BACK TO QUOTES"
                  primaryButtonOnClick={() => {
                    window.open(
                      `${publicRuntimeConfig.urls.legacyAppUrl}/QA.aspx?Q=d873b961-508a-4781-a349-d6619d00a852`,
                      '_self'
                    );
                  }}
                  illustration="error"
                />

              )}
            </>
          )}
        </ThemeProvider>
      </>
    );
  }
}

export default MyApp;
